// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-enrolement-form-index-tsx": () => import("./../../../src/pages/enrolement/Form/index.tsx" /* webpackChunkName: "component---src-pages-enrolement-form-index-tsx" */),
  "component---src-pages-enrolement-form-steps-step-1-index-tsx": () => import("./../../../src/pages/enrolement/Form/Steps/Step1/index.tsx" /* webpackChunkName: "component---src-pages-enrolement-form-steps-step-1-index-tsx" */),
  "component---src-pages-enrolement-form-steps-step-2-index-tsx": () => import("./../../../src/pages/enrolement/Form/Steps/Step2/index.tsx" /* webpackChunkName: "component---src-pages-enrolement-form-steps-step-2-index-tsx" */),
  "component---src-pages-enrolement-form-steps-step-3-index-tsx": () => import("./../../../src/pages/enrolement/Form/Steps/Step3/index.tsx" /* webpackChunkName: "component---src-pages-enrolement-form-steps-step-3-index-tsx" */),
  "component---src-pages-enrolement-form-steps-step-4-index-tsx": () => import("./../../../src/pages/enrolement/Form/Steps/Step4/index.tsx" /* webpackChunkName: "component---src-pages-enrolement-form-steps-step-4-index-tsx" */),
  "component---src-pages-enrolement-index-tsx": () => import("./../../../src/pages/enrolement/index.tsx" /* webpackChunkName: "component---src-pages-enrolement-index-tsx" */),
  "component---src-pages-gallery-index-tsx": () => import("./../../../src/pages/gallery/index.tsx" /* webpackChunkName: "component---src-pages-gallery-index-tsx" */),
  "component---src-pages-home-components-enquire-index-tsx": () => import("./../../../src/pages/Home/components/Enquire/index.tsx" /* webpackChunkName: "component---src-pages-home-components-enquire-index-tsx" */),
  "component---src-pages-home-components-hero-index-tsx": () => import("./../../../src/pages/Home/components/Hero/index.tsx" /* webpackChunkName: "component---src-pages-home-components-hero-index-tsx" */),
  "component---src-pages-home-components-philosophy-index-tsx": () => import("./../../../src/pages/Home/components/Philosophy/index.tsx" /* webpackChunkName: "component---src-pages-home-components-philosophy-index-tsx" */),
  "component---src-pages-home-components-we-care-index-tsx": () => import("./../../../src/pages/Home/components/WeCare/index.tsx" /* webpackChunkName: "component---src-pages-home-components-we-care-index-tsx" */),
  "component---src-pages-home-components-we-care-list-item-tsx": () => import("./../../../src/pages/Home/components/WeCare/ListItem.tsx" /* webpackChunkName: "component---src-pages-home-components-we-care-list-item-tsx" */),
  "component---src-pages-home-index-tsx": () => import("./../../../src/pages/Home/index.tsx" /* webpackChunkName: "component---src-pages-home-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-parents-index-tsx": () => import("./../../../src/pages/parents/index.tsx" /* webpackChunkName: "component---src-pages-parents-index-tsx" */),
  "component---src-pages-parents-inner-index-tsx": () => import("./../../../src/pages/parents/Inner/index.tsx" /* webpackChunkName: "component---src-pages-parents-inner-index-tsx" */),
  "component---src-pages-rates-block-tsx": () => import("./../../../src/pages/rates/Block.tsx" /* webpackChunkName: "component---src-pages-rates-block-tsx" */),
  "component---src-pages-rates-index-tsx": () => import("./../../../src/pages/rates/index.tsx" /* webpackChunkName: "component---src-pages-rates-index-tsx" */),
  "component---src-pages-team-components-team-member-tsx": () => import("./../../../src/pages/team/components/TeamMember.tsx" /* webpackChunkName: "component---src-pages-team-components-team-member-tsx" */),
  "component---src-pages-team-index-tsx": () => import("./../../../src/pages/team/index.tsx" /* webpackChunkName: "component---src-pages-team-index-tsx" */)
}

